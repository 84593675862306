import { Button, Grid } from '@mui/material'
import Carousel from 'nuka-carousel/lib/carousel'
import React, { useEffect, useRef, useState } from 'react'
import img from '../Assets/statue.jpg'
import Service from './Service'
import ServicePhone from './ServicePhone'
import { useNavigate } from 'react-router-dom'

function SectionTemplate({data}) {
  const [color, setcolor] = useState("white")
    const [txtcolor, settxtcolor] = useState("black")
    const navigate= useNavigate()
    const ref= useRef(null)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        const changeColor = () => {
            console.log(color)
            if (window.scrollY >= 1800 && window.scrollY <= 2800 ) {
              setcolor('#000000');
              settxtcolor('#ffffff');
            } 

            
            else {
              setcolor('#e3dfdc');
              settxtcolor('#000000');
          }
        };
        window.addEventListener('scroll', changeColor);
      }, []);
  return (
    <div>
        <div data-aos='fade-down' className='headers flex p-[20px] pt-[60px] md:flex-row flex-col justify-between border border-[#e3dfdc] border-b-black'>
            <div className='flex flex-row md:flex-col justify-between' >
                <div >SERVICES</div>
                <div className='text-6xl font-bold ' >{data?.index}</div>
            </div>
            <div className='text-5xl mt-6 md:my-0 md:text-8xl text-right ' >{data?.title?.toUpperCase()}</div>
        </div>
        <div className=' py-6 md:py-0 md:h-[90vh] overflow-hidden' >
            <div data-aos='fade-up' className={`w-screen px-[20px] h-[40vh] md:h-screen bgImg ${data?.imgclass}`} alt="statue" />
        </div>
        
        <div className="flex md:flex-row flex-col bg-[#ffffff] my-4 px-[20px]">
            <div className="content border border-transparent border-r-black flex-0.5 flex items-center">
                <div className=' h-full flex flex-col justify-between ' >
                    <div>
                      <div className='flex flex-col text-base py-8 p-0 pr-3  md:flex-row md:space-x-5 ' >
                          <div className='max-w-[400px] mr-6 md:mr-0 mb-6 md:mb-0'>
                            {data?.subTitle}
                          </div>
                          <div className='max-w-[400px] m-0'>
                            {data?.desc}
                          </div>
                      </div>
                    </div>
                </div>
            </div>
            <div className="flex-1 flex flex-col justify-start p-3 text-5xl bold h-[auto]">
              <Button onClick={() => navigate('/contact')} variant='contained' class='content button-glitch m-auto mt-5 '>get started today [+]</Button>
            </div>
      </div>
{/* 
      <div className="flex md:flex-row-reverse flex-col border border-y-black  bg-[#e3dfdc] min-h-[30vh] px-[20px]">
        <div className="flex-1 bold h-[auto]">
          <div className='my-auto p-4 flex flex-col items-center justify-between h-full'>
            <div className=" flex-1 flex flex-col items-center justify-center h-full">
            <Carousel wrapAround={true} className='h-full max-w-[500px]' >
              <div className='text-8xl text-center flex flex-col h-[300px] md:h-[400px]  m-auto items-center justify-center'>
                  <div>25%</div>
                  <div className='text-base' >customer growth</div>
              </div>
              <div className='text-8xl text-center flex flex-col h-[300px] md:h-[400px] m-auto items-center justify-center'>
                  <div>25%</div>
                  <div className='text-base' >customer growth</div>
              </div>
              <div className='text-8xl text-center flex flex-col h-[300px] md:h-[400px]  m-auto items-center justify-center'>
                  <div>25%</div>
                  <div className='text-base' >customer growth</div>
              </div>
              </Carousel>
            </div>
          </div>
        </div>

        <div className=" border border-r-black flex-0.5 flex items-center py-5">
          <div data-aos='fade-up' className=' h-full flex flex-col justify-between' >
            <div className='max-w-[400px] text-4xl'>
                więzi. W 247 tworzymy nagradza
            </div>
            <div>
              <div className='flex flex-col space-y-4 text-sm py-8' >
                <div className='max-w-[400px] m-0 mr-4'>
                  więzi. W 247 tworzymy nagradzane identyfikacje oraz branding, których siłą jest zawsze to samo. DNA marek naszych Klientów. Docieramy do esencji i pełni potencjału brandów. Wydobywamy je, zadając pytania i uważnie słuchając. Kwestionując oczywistości. Wypełniamy unikalną i spersonalizowaną treścią projektową.
                </div>
              </div>
              <div className='flex' >
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="flex flex-col border border-y-black md:flex-row bg-[#ffffff] min-h-[80vh] px-[20px]">
        <div className="flex-1  text-5xl bold h-[auto]">
          <div className='my-auto p-4 flex flex-col items-center justify-between h-full'>
            <div className={`flex-1 flex items-center justify-center md:w-[55vw] bgImg ${data?.heroImg2}`}>
              {/* <img className='object-cover mt-auto md:w-[55vw] w-screen ' src={img} alt="" /> */}
            </div>
          </div>
        </div>

        <div className=" border border-transparent pb-5 border-l-black flex-0.5 flex items-center">
          <div data-aos='fade-up' className=' h-full flex flex-col space-y-6' >
            <div className='headers py-8 px-5 text-3xl md:text-5xl max-w-[500px]' >{data?.subtitle2?.toUpperCase()}</div>
            <div>
              <div className='flex flex-col space-y-4 text-base py-8 px-5' >
                <div className='content max-w-[400px] m-0'>
                  {data?.desc2}
                </div>
              </div>
              <div className='flex' >
                <Button onClick={() => ref.current.scrollIntoView() } variant='contained' class='content button-glitch m-auto mt-5 '>explore the possibilities[+]</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref} className='bg-[#ffffff] p-[20px]'>
        
        Explore our {data?.title} Solutions.
      </div>
      <div data-aos='fade-up' className='bg-[#ffffff] p-[20px] hidden md:block border border-transparent border-b-black '>
        {
          data?.list?.map((item, index) =>  
              <Service index={index} key={index} data={item} col={color} txcol={txtcolor}/>
          )
        }
      </div>
      <div className='block md:hidden'>
        <Carousel data-aos='fade-up' wrapAround={true} className='h-full max-w-[500px] w-full mx-auto' >
        {
          data?.list?.map((item, index) =>  
            <ServicePhone  index={index} key={index} data={item}/>
          )
        }
        </Carousel>
      </div>


      {/* <div className='bg-[#e3dfdc] border border-y-black px-[20px]'>
        <Grid  container xl={12} className=''>
            <Grid item xl={4} md={4} xs={12} sm={12}>
            <div data-aos='fade-up' className=" border flex-0.5 h-full flex flex-col justify-end">
              <div className=' h-full flex flex-col justify-end ' >
                <div className='py-8 px-5 text-5xl max-w-[500px] headers' >GET MEDIA THAT MAKES AN IMPACT AND DRIVES ROI</div>
                <div>
                  <div className='flex flex-col space-y-4 text-sm py-8 px-5' >
                    <div className='max-w-[400px] m-0 content'>
                      więzi. W 247 tworzymy nagradzane identyfikacje oraz branding, których siłą jest zawsze to samo. DNA marek naszych Klientów. Docieramy do esencji i pełni potencjału brandów. Wydobywamy je, zadając pytania i uważnie słuchając. Kwestionując oczywistości. Wypełniamy unikalną i spersonalizowaną treścią projektową.
                    </div>
                  </div>
                  <div className='flex' >
                    <Button variant='contained' className='btnBlack2'>Ignite my digital brand [+]</Button>
                  </div>
                </div>
              </div>
            </div>
            </Grid>
            <Grid sx={{borderLeft:'1px solid black',py:4} } item xl={8} md={8} xs={12} sm={12} >
                <Grid container xl={12}>
                    <Grid xl={12} item>
                        <div data-aos='fade-down' className='px-4 text-3xl md:text-7xl headers '>END-TO-END INFLUENCER PARTNERSHIPS AND MANAGEMENT</div>
                        <div className="flex-1  text-5xl bold h-[auto]">
                          <div className='my-auto p-4 flex flex-col items-center justify-between h-full'>
                            <div className=" flex-1 flex items-center justify-center h-full">
                              <img className='object-cover mt-auto w-screen ' src={img} alt="" />
                            </div>
                          </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div> */}

    </div>
  )
}

export default SectionTemplate