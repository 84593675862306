import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Projects from '../Components/Projects'
import headBanner from '../Assets/homeBanner.png'
import { Button } from '@mui/material'
import Hoverer from '../Components/Footer/Hoverer/Hoverer'
import { homeData } from '../Data/data'
import serviceImg from '../Assets/Metaverse Services.png'
import { useLocation, useNavigate } from 'react-router-dom'
import img1 from '../Assets/dpworld.png'
import img2 from '../Assets/NITCO Logo.png'
import img3 from '../Assets/kenvault 300.png'
import Carousel from 'nuka-carousel/lib/carousel'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Home() {
    const options = {
        followCursor: true,
        shiftX: 20,
        shiftY: 0,
    }

    const navigate = useNavigate()
    const scrollRight = () => {
        document.querySelector('.slider-control-centerright > button').click();
    }

    const scrollLeft = () => {
        document.querySelector('.slider-control-centerleft > button').click();
    }
    return (
        <div className=''>
            <div className="min-h-fit bg-[#e3dfdc] md:min-h-screen ">
                <div className="banner-content">
                    <Navbar />
                </div>
                <div className='overflow-hidden'>
                    <div className='h-[250px] md:h-[550px] md:scale-[1.2] bgImg MainBanner'>
                    </div>
                </div>
                <div className='bg-black ' >
                    <div className=' text-white text-[25px] py-8 px-[10px] text-center md:text-7xl max-w-[90%] mx-auto' >
                        <div className='md:leading-tight headers' data-aos="fade-up">{homeData?.title.toUpperCase()} <span className='bgImg midImg1 hidden md:inline mt-[20px] px-[100px]' ></span>METAVERSE </div>
                        <div data-aos="fade-up" className='text-[10px] w-2/3  mx-auto my-7 md:text-base max-w-[700px] content' >
                            {homeData?.subTitle}
                        </div>
                        <div className='flex' >
                            <Button onClick={() => navigate('/contact')} data-aos="fade-up" variant='contained' class='content button-glitch m-auto mt-5 '>join the digital revolution [+]</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className=' bg-white flex p-5 pt-[20px] border border-white border-t-gray-500  flex-col-reverse md:flex-row'>
                <div className='md:w-1/3 flex flex-col justify-between  md:space-y-12 px-4'>
                    <div data-aos="fade-up" className='text-[25px] md:text-7xl text-center md:text-left my-5 headers' >
                        {homeData?.title2.toUpperCase()}
                    </div>
                    <div data-aos="fade-down" className='text-base content' >
                        {homeData?.subTitle2}
                    </div>
                </div>
                <div className='md:w-2/3 px-4 flex flex-col space-y-5 border border-white border-l-gray-500 '>
                    <div className=' text-base ' >
                        ABOUT US
                    </div><div data-aos="fade-down" className='h-[250px] md:h-[550px] bgImg weAreMOk'>
                    </div>
                </div>
            </div>

            <div className='flex p-5 pt-[20px] md:py-6  border border-orange-500 border-t-gray-500 border-b-gray-800  bg-orange-500 flex-col md:flex-row'>
                <div className='  w-full md:w-1/3 px-4  border-orange-500  md:border border-r-gray-800 '>
                    <div data-aos="fade-up" className='text-[25px] flex-col headers md:text-5xl text-center w-full md:pr-5 md:max-w-[500px] md:text-left' >
                        THIS IS <br /> WHAT WE DO
                        <p>
                            <Button onClick={() => { navigate('./capabilities') }} data-aos="fade-up" variant='contained' class='content button-glitch m-auto my-5 '>Our Capabilities [+]</Button>
                        </p>
                    </div>
                    {/* <Button variant='contained' className='btnBlack'>Our Stories [+]</Button> */}

                </div>
                <div className='md:w-2/3 flex flex-col space-y-12 px-4'>
                    <div data-aos="fade-up" className='text-[12px] md:text-base content ' >
                        {homeData?.subTitle3}
                    </div>
                </div>
            </div>

            <Hoverer title="BUILD IMMERSIVE EXPERIENCES" image="buildImmensive" />
            <Hoverer title="CREATE VIRTUAL WORLDS" image="createVirtual" />
            <Hoverer title="DIGITAL BRAND REALMS" image="DigRealms" />

            <div className='bg-[#ffffff] border-b border-b-gray-500 text-black  py-8 px-[30px] flex flex-col md:flex-row' >
                <div className='md:max-w-[800px] flex flex-col justify-between'>
                    <div data-aos="fade-up" className='text-[23px] text-center md:text-7xl md:text-left font-bold headers'>OUR <br /> METAVERSE <br />SERVICES</div>
                    <div>
                        <div data-aos="fade-up" className='flex flex-col  text-base py-8 md:flex-row content' >
                            <div className='md:max-w-full my-3 md:my-0'>
                                {homeData?.subTitle4}
                            </div>
                            <div className='md:max-w-[400px] md:mx-8'>
                                {homeData?.subTitle5}
                            </div>
                        </div>
                        <Button onClick={() => { navigate('./solutions') }} data-aos="fade-up" variant='contained' class='content button-glitch m-auto my-5 '>Our SERVICES [+]</Button>
                    </div>
                </div>
                <div className='flex flex-3' >
                    <img className='bgImg' src={serviceImg} alt="" />
                </div>
            </div>
            {
                homeData?.list?.map((item, index) =>
                    <Projects key={index} data={item} />
                )
            }

            <div className='bg-black  text-base md:text-7xl text-white flex py-5 font-extrabold headers'>
                <div data-aos="fade-down" className='w-1/2 flex flex-col  p-3 md:py-8 text-white ' > OUR CLIENTS</div>
                {/* <div data-aos="fade-down" className='w-1/2 flex flex-col p-3' > OUR VALUES</div> */}
            </div>
            <div className="md:h-[700px] bg-white md:overflow-hidden" >
                {/* <div className='text-7xl font-light px-7 py-[50px]' >Our Work</div> */}
                <div className='grid md:grid-cols-3 grid-cols-1 px-7' >
                    <div className='min-h-[300px]'>
                        <div className='py-5 mx-5 flex justify-between border border-transparent border-b-gray-500 ' >
                            <div>Featured</div>
                            {/* <div>Beauty</div> */}
                        </div>
                        <div className='p-10 my-5 flex flex-col border border-transparent border-x-gray-500  ' >
                            <div className=' text-center font-bold text-5xl py-4 headers' >DP WORLD</div>
                            <div className='text-base text-center py-7' ></div>
                            <div className='bg-[#f0f5f7] overflow-hidden group flex hover:h-[400px] hover:mt-[-50px] transform duration-500 cursor-pointer h-[300px]' >
                                <img className='h-32 m-auto transition duration-700 group-hover:scale-150 ' src={img1} alt="" />
                            </div>
                        </div>
                        <div className='border border-transparent border-b-gray-500 mx-3 flex-1 ' ></div>
                    </div>

                    <div className='min-h-[300px]'>
                        <div className='py-5 mx-5 flex justify-between border border-transparent border-y-gray-500  md:border-transparent md:border-b-gray-500 ' >
                            <div></div>
                            <div className='opacity-0' >Beauty</div>
                        </div>
                        <div className='p-10 my-5 flex flex-col border border-transparent  ' >
                            <div className=' text-center font-bold text-5xl py-4 headers' >NITCO</div>
                            <div className='text-base text-center py-7' ></div>
                            <div className='bg-[#f0f5f7] overflow-hidden group flex hover:h-[400px] hover:mt-[-50px] transform duration-500 cursor-pointer h-[300px]' >
                                <img className='h-24 m-auto transition duration-700 group-hover:scale-[1.2] ' src={img2} alt="" />
                            </div>
                        </div>
                        <div className='border border-transparent border-b-gray-500 mx-3 flex-1 ' ></div>
                    </div>

                    <div className='min-h-[300px] '>
                        <div className='py-5 mx-5 flex justify-between border border-transparent border-y-gray-500  md:border-transparent md:border-b-gray-500 ' >
                            <div></div>
                            <div className='opacity-0' >Beauty</div>
                        </div>
                        <div className='p-10 my-5 flex flex-col border border-transparent border-x-gray-500  ' >
                            <div className=' text-center font-bold text-5xl py-4 headers' >KENVAULT</div>
                            <div className='text-base text-center py-7' ></div>
                            <div className='bg-[#f0f5f7] overflow-hidden group flex hover:h-[400px] hover:mt-[-50px] transform duration-500 cursor-pointer h-[300px]' >
                                <img className='h-32 m-auto transition duration-700 group-hover:scale-150 ' src={img3} alt="" />
                            </div>
                        </div>
                        <div className='border border-transparent border-b-gray-500 mx-3 flex-1 ' ></div>
                    </div>

                </div>

            </div>
            <div>
                <div className='pt-[50px] pb-[50px] px-7 bg-white '>
                    <div className='text-[20px] md:text-4xl pl-7 font-light' >DON'T  TAKE  OUR  WORD  FOR  IT </div>
                    <div className="flex justify-end px-5" >
                        <Button onClick={scrollLeft} className="scrollButtons" ><ArrowBackIcon/></Button>
                        <Button onClick={scrollRight} className="scrollButtons" ><ArrowForwardIcon/></Button>
                    </div>
                    <Carousel wrapAround={true} slidesToShow={1.1} autoplay={1} >

                    <div className='p-7 border border-black  m-7'>
                            <div className='flex flex-col md:flex-row'>
                                <div className='md:w-2/5 ' >
                                    <div className='bg-[#CEDCE0] h-[400px] ' ></div>
                                </div>
                                <div className=' flex flex-col md:w-3/5 p-5 pl-10`'>
                                    <div className='p-5'>
                                        <div className='my-2 text-red-600 text-4xl '>"</div>
                                        <div className='my-2 text-xl'>
                                            Working with Mokmeta was a game-changer for our fintech product. Their team of experts provided us with a Metaverse solution that not only enhanced the user experience but also increased user engagement and retention. The level of creativity and innovation they brought to the table was impressive, and they worked collaboratively with us every step of the way to ensure that our vision was brought to life.
                                        </div>
                                        <div className='font-bold mt-8 text-3xl' >Kiran Srinivas</div>
                                        <div className='font-extralight text-lg' >CEO | KENVAULT </div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-7 border border-black  m-7'>
                            <div className='flex flex-col md:flex-row'>
                                <div className='md:w-2/5 ' >
                                    <div className='bg-[#CEDCE0] h-[400px] ' ></div>
                                </div>
                                <div className=' flex flex-col md:w-3/5 p-5 pl-10`'>
                                    <div className='p-5'>
                                        <div className='my-2 text-red-600 text-4xl '>"</div>
                                        <div className='my-2 text-xl'>
                                            We had the pleasure of working with Mokmeta to develop a Metaverse solution for our communication needs, and we could not be more satisfied with the outcome. Mokmeta's team of experts was incredibly knowledgeable, professional, and responsive throughout the entire project, and they were able to deliver a solution that exceeded our expectations.
                                        </div>
                                        <div className='font-bold mt-8 text-3xl' >Chandra Yatagiri</div>
                                        <div className='font-extralight text-lg' >CEO | NITCO </div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-7 border border-black  m-7'>
                            <div className='flex flex-col md:flex-row'>
                                <div className='md:w-2/5 ' >
                                    <div className='bg-[#CEDCE0] h-[400px] ' ></div>
                                </div>
                                <div className=' flex flex-col md:w-3/5 p-5 pl-10`'>
                                    <div className='p-5'>
                                        <div className='my-2 text-red-600 text-4xl '>"</div>
                                        <div className='my-2 text-xl'>
                                            Working with Mokmeta was a game-changer for our fintech product. Their team of experts provided us with a Metaverse solution that not only enhanced the user experience but also increased user engagement and retention. The level of creativity and innovation they brought to the table was impressive, and they worked collaboratively with us every step of the way to ensure that our vision was brought to life.
                                        </div>
                                        <div className='font-bold mt-8 text-3xl' >Kiran Srinivas</div>
                                        <div className='font-extralight text-lg' >CEO | KENVAULT </div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default Home