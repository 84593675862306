import React, { useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import img from '../Assets/statue.jpg'
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer/Footer';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { solutionData } from '../Data/data';

function Solutions() {
    const navigate = useNavigate()
    const ref =  useRef(null)
    
    return (
        <div className='overflow-hidden'>
            <Navbar />
            <div className="flex bg-[#ffffff] md:min-h-screen pt-[50px] px-[20px] flex-col-reverse md:flex-row">
                <div className="flex-1 flex text-4xl md:text-7xl bold">
                    <div className='md:h-[95vh] my-auto py-4 flex flex-col justify-between'>
                        <div data-aos="fade-down" className='headers' >{solutionData?.title?.toUpperCase()}</div>
                        <div data-aos="fade-up">
                            <div className='flex md:space-x-8 text-base py-8 flex-col md:flex-row content ' >
                                <div className='my-6 md:my-0 md:max-w-[250px]'>
                                    {solutionData?.subTitle}
                                </div>
                                <div className='md:max-w-[400px]'>
                                    {solutionData?.desc}
                                </div>
                            </div>
                            <div className='flex' >
                                <Button onClick={() => navigate('/contact')} variant='contained' class='content button-glitch m-auto mt-5 '>build the future with us [+]</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" border md:border-l-gray-500  flex-1 md:py-7 md:pl-6 flex items-center">
                    <div className='h-[250px] md:h-full w-full bgImg futureIs'>
                </div>
                </div>
            </div>
            <div className="flex bg-orange-500  p-[20px] flex-col md:flex-row">
                <div className="flex-1 text-2xl md:text-5xl bold">
                    <div data-aos="fade-up" className='my-auto py-4 flex flex-col justify-between md:max-w-[750px] headers'>
                        <div>{solutionData?.title2?.toUpperCase()}</div>CONNECTED WORLD
                    </div>
                </div>

                <div data-aos="fade-down" className=" border border-l-gray-500  border-transparent flex-0.5 flex items-center content">
                    <div>
                        <div className='flex space-x-8 text-base py-8 px-5' >
                            <div className='max-w-[400px]'>
                                {solutionData?.subTitle2}
                            </div>
                        </div>
                        <div className='flex' >
                        <Button onClick={() => ref.current.scrollIntoView() } variant='contained' class='content button-glitch m-auto mt-5 '>imagine the possibilities [+]</Button>
                        </div>
                    </div>`
                </div>
            </div>
            {/* <div className="pt-[9vh] bg-[#ffffff]" ref={ref}></div> */}
        {
            solutionData?.list?.map((item, index) =>
                <div className={ index %2 ===1 ? 'flex p-5 pt-[20px] bg-[#ffffff] border border-transparent border-b-gray-500  flex-col-reverse md:flex-row' : 
                'flex p-5 pt-[20px] bg-[#ffffff] border border-transparent border-b-gray-500  flex-col-reverse md:flex-row-reverse'}>
                    <div data-aos="fade-down" className='md:w-1/3 flex flex-col justify-between  md:space-y-12 px-4'>
                        <div className='py-8 px-5 text-9xl headers' >{item?.index}</div>
                        <div>
                            <div className='flex flex-col space-y-4 text-sm py-8 px-5' >
                                <div className='max-w-[400px] text-4xl headers'>
                                   {item?.subTitle.toUpperCase()}
                                </div>
                                <div className='max-w-[400px] text-base m-0 content'>
                                    {item?.desc}
                                </div>
                            </div>
                            <div className='flex' >
                                <Button onClick={() => navigate(item?.link)} variant='contained' class='content button-glitch m-auto mt-5 '>discover more [+]</Button>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-up" className={index % 2 === 1 ? 'md:w-2/3 px-4 flex flex-col space-y-5 border border-transparent border-l-gray-500 ' :
                        'md:w-2/3 px-4 flex flex-col space-y-5 border border-transparent border-r-gray-500 '}>
                        <div className=' text-2xl font-bold md:text-7xl headers' >
                        {index===4 ? "PRODUCT":"METAVERSE"} <br/>{item?.title.toUpperCase()}
                        </div>
                        <div className={`h-[250px] md:h-[550px] bgImg ${item?.class}`}>
                        </div>
                    </div>
                </div>
            )
        }

        </div>
    )
}

export default Solutions