import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Navbar from '../Components/Navbar'
import mimg1 from '../Assets/Siva.png'
import mimg2 from '../Assets/siva2.jpeg'
import img2 from '../Assets/Sidhu.png'
import simg2 from '../Assets/sid.jpeg'
import img3 from '../Assets/Sathwik.png'
import simg3 from '../Assets/Satwik.jpg'
import { aboutdata } from '../Data/data'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../index.css'

function AboutUs() {
    const [color, setcolor] = useState("white")
    const [txtcolor, settxtcolor] = useState("black")
    const [img, setimg] = useState(1)
    const navigate = useNavigate()
    const [myimg1, setmyimg1] = useState(mimg1);
    const [myimg2, setmyimg2] = useState(img2);
    const [myimg3, setmyimg3] = useState(img3);

    useEffect(() => {
        const changeColor = () => {
            if (window.scrollY >= 1500) {
                setimg(0)
            }

            else {
                setimg(1)
            }
        };
        window.addEventListener('scroll', changeColor);
    }, []);

    return (
        <div className='bg-white'>
            <Navbar />
            <div className=' text-yellow-200 bg-black flex flex-col md:flex-row relative md:mt-[40px] min-h-[92vh] pt-5'>
                <div className='md:w-1/2 relative -[80px] md:p-0' >
                    <div className={img ? `bgImg stickfix -z-0 aboutTopBanner w-full h-screen p-1 ` : `bgImg absolute -z-0  w-full h-screen p-1 `}>
                    </div>
                </div>
                <div className='md:w-1/2 flex flex-col justify-between p-5' >
                    <div data-aos="fade-down" className='text-5xl font-bold headers mt-4 md:mt-0 '>{aboutdata?.title?.toUpperCase()}</div>
                    <div data-aos="fade-up" className='text-justify md:text-left flex flex-col md:flex-row md:space-x-8   py-[40px] justify-between content' >
                        <div className='md:w-1/5'>{aboutdata?.sideText1} </div>
                        <div className='my-2 md:my-0 md:w-4/5'>
                            <p>
                                {aboutdata?.sidetext2}
                            </p>
                            <Button onClick={() => navigate('/contact')} variant='contained' className='greenBtn' >Lets build [+]</Button>
                        </div>
                    </div>
                    <div data-aos="fade-up" className='text-5xl font-bold  border border-transparent border-t-yellow-200 pt-5 headers ' >{aboutdata?.title2?.toUpperCase()} </div>
                    <div className='text-yellow-200 bg-black flex py-2 content'>
                        <div data-aos="fade-down" className='flex flex-col '></div>
                        <div data-aos="fade-up" className='text-justify md:text-left  flex flex-col md:space-x-4 md:flex-row pt-[30px] justify-between' >
                            <div className='md:w-1/5'>{aboutdata?.sideText3}</div>
                            <div className='my-2 md:my-0 md:mb-4 md:w-4/5'>
                                <p>
                                    {aboutdata?.sideText4}
                                </p>
                                <Button onClick={() => navigate('/contact')} variant='contained' className='greenBtn' >Lets build [+]</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='z-10 relative bg-white'>
                <div data-aos="fade-up" className=' text-[25px] text-center md:text-left md:text-7xl p-4 py-10 font-extrabold headers' >
                    THE VALUES IN OUR DNA
                </div>

                <div className='bgImg valuesBanner h-[50vh] md:h-[80vh] '></div>
                <div data-aos="fade-up" className='grid grid-flow-row md:grid-flow-col py-8 bg-white' >
                    {
                        aboutdata?.Values?.map((item, index) =>
                            <div key={index} className="md:pb-[150px] px-3 border border-transparent border-r-gray-500 ">
                                <div className='text-4xl font-extrabold headers ' >{item?.title?.toUpperCase()}</div>
                                <div className='text-base py-3 content' >{item?.desc}</div>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className='z-30 bg-black relative' >
                <div className='bg-black text-base md:text-7xl text-white flex py-5 font-extrabold headers'>
                    <div data-aos="fade-down" className='w-1/2 flex flex-col  p-3 md:py-8 ' > OUR PEOPLE</div>
                    {/* <div data-aos="fade-down" className='w-1/2 flex flex-col p-3' > OUR VALUES</div> */}
                </div>
                <div className="pb-10 bg-black text-white md:overflow-hidden" >
                    {/* <div className='text-7xl font-light px-7 py-[50px]' >Our Work</div> */}
                    <div className='grid md:grid-cols-3 grid-cols-1 px-7' >
                        <div className='min-h-[300px]'>
                            <div className='py-5 mx-5 flex justify-between border border-transparent border-b-gray-500 ' >
                                {/* <div className='opacity-0' >Featured</div> */}
                                {/* <div>Beauty</div> */}
                            </div>
                            <div className='p-10 my-5 flex flex-col border border-transparent border-x-gray-500  ' >
                                {/* <div className=' text-center font-bold text-5xl py-4 headers' >DP WORLD</div>
                                <div className='text-base text-center py-4' >Virtual ports/ Training</div> */}
                                <div className='bg-[#f0f5f7] overflow-hidden group flex transform duration-500 cursor-pointer h-[430px]' >
                                    <img className='h-full w-full object-cover m-auto transition duration-700 scale-[1.1] group-hover:scale-[1] ' onMouseEnter={() => setmyimg1(mimg2)} onMouseLeave={() => setmyimg1(mimg1)} src={myimg1} alt="" />
                                </div>
                                <div className='flex justify-between py-3 text-[20px]'>
                                    <div>
                                        <p>SIVA DANTULURI </p>
                                        Co-founder
                                    </div>
                                    <Link to='https://www.linkedin.com/in/sivadantuluri17/' >
                                        <LinkedInIcon/>
                                    </Link>
                                </div>
                            </div>
                            <div className='border border-transparent border-b-gray-500 mx-3 flex-1 ' ></div>
                        </div>

                        <div className='min-h-[300px]'>
                            <div className='py-5 mx-5 flex justify-between border border-transparent border-y-gray-500  md:border-transparent md:border-b-gray-500 ' >
                                <div></div>
                                {/* <div className='opacity-0' >Beauty</div> */}
                            </div>
                            <div className='p-10 my-5 flex flex-col border border-transparent  ' >
                                {/* <div className=' text-center font-bold text-5xl py-4 headers' >NITCO</div>
                                <div className='text-base text-center py-4' >Communication</div> */}
                                <div className='bg-[#f0f5f7] overflow-hidden group flex transform duration-500 cursor-pointer h-[430px]' >
                                    <img className='h-full w-full object-cover  m-auto transition duration-700 scale-[1.1] group-hover:scale-[1] ' onMouseLeave={() => setmyimg2(img2)} onMouseEnter={() => setmyimg2(simg2)} src={myimg2} alt="" />
                                </div>
                                <div className='flex justify-between py-3 text-[20px]'>
                                    <div>
                                        <p>SIDHARTHA MANDAPATI</p>
                                        Co-founder
                                    </div>
                                    <Link to='https://www.linkedin.com/in/sidhartha-varma-52843252/' >
                                        <LinkedInIcon/>
                                    </Link>
                                </div>
                            </div>
                            <div className='border border-transparent border-b-gray-500 mx-3 flex-1 ' ></div>
                        </div>

                        <div className='min-h-[300px] '>
                            <div className='py-5 mx-5 flex justify-between border border-transparent border-y-gray-500  md:border-transparent md:border-b-gray-500 ' >
                                <div></div>
                                {/* <div className='opacity-0' >Beauty</div> */}
                            </div>
                            <div className='p-10 my-5 flex flex-col border border-transparent border-x-gray-500  ' >
                                {/* <div className=' text-center font-bold text-5xl py-4 headers' >KENVAULT</div>
                                <div className='text-base text-center py-4' >Fintech</div> */}
                                <div className='bg-[#f0f5f7] overflow-hidden group flex transform duration-500 cursor-pointer h-[430px]' >
                                    <img className='h-full w-full object-cover m-auto transition duration-700 scale-[1.1] group-hover:scale-[1] ' onMouseLeave={() => setmyimg3(img3)} onMouseEnter={() => setmyimg3(simg3)} src={myimg3} alt="" />
                                </div>
                                <div className='flex justify-between py-3 text-[20px]'>
                                    <div>
                                        <p> SATWIK KOMMABHATLA  </p>
                                        Head of Product
                                    </div>
                                    <Link to='https://www.linkedin.com/in/satwik-kommabhatla/' >
                                        <LinkedInIcon/>
                                    </Link>
                                </div>
                            </div>
                            <div className='border border-transparent border-b-gray-500 mx-3 flex-1 ' ></div>
                        </div>
                    </div>
                </div>
                {
                    aboutdata?.list?.map((item, index) =>
                        <div key={index} style={{ color: `${txtcolor}`, backgroundColor: `${color}`, borderTop: `1px solid ${txtcolor}` }} data-aos="fade-up" className='flex p-5 pt-[20px]  flex-col-reverse md:flex-row-reverse transition ease-in duration-300'>
                            <div className=' content md:w-1/3 flex flex-col justify-end md:space-y-12 px-4 transition ease-in duration-300 py-0 '>
                                <div className='text-[15px] md:text-[28px] text-center md:text-left font-bold ' >
                                    {item?.desc.toUpperCase()}
                                    {/* {
                                    item?.list?.map((dt) => 
                                        <div key={dt} className='py-3'>
                                            <u>{dt?.title}</u> {dt?.desc}
                                        </div>
                                    )
                                } */}
                                    <div>
                                        <Button onClick={() => navigate(item?.link)} fullWidth variant='contained' class='content button-glitch m-auto mt-5 '>Talk to us [+]</Button>
                                    </div>
                                </div>
                                {/* <div className='text-base' >
                        brandów. Wydobywamy je, zadając pytania więzi. W 247 tworzymy nagradza
                        DNA marek naszych Klientów. Docieramy do esencji i pełni potencjału brandów. Wydobywamy je, zadając pytania więzi. W 247 tworzymy nagradza
                    </div> */}
                            </div>
                            <div style={{ borderRight: `1px solid ${txtcolor}` }} className='md:w-2/3 px-4 flex flex-col  transition ease-in duration-300 '>
                                <div className='text-[25px] md:text-6xl font-extrabold break-words headers ' >
                                    {/* {item?.index} <br/> */}
                                    {("Ambitiously Futuristic.")?.toUpperCase()}<br />
                                    {("Boldly Imaginative.")?.toUpperCase()}<br />
                                    {("Creatively Pioneering.")?.toUpperCase()}<br />
                                    {("Digitally Daring.")?.toUpperCase()}
                                </div>
                                <div className='h-[250px] md:h-[350px] mt-[50px] md:mt-[150px] bgImg ourPeople'>
                                </div>
                            </div>
                        </div>
                    )

                }
            </div>
        </div>
    )
}

export default AboutUs