import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../Components/Footer/Footer'
import Navbar from '../Components/Navbar'
import processData from '../Data/data'

function Process() {
    const [color, setcolor] = useState("white")
    const [txtcolor, settxtcolor] = useState("black")
    
    useEffect(() => {
        const changeColor = () => {
            if (window.scrollY >= 1000 && window.scrollY <= 3000 ) {
              setcolor('#000000');
              settxtcolor('#ffffff');
            } 
            
            else {
              setcolor('#ffffff');
              settxtcolor('#000000');
          }
        };
        window.addEventListener('scroll', changeColor);
      }, []);
  return (
    <div>
        <div className="min-h-fit bg-[#e3dfdc] md:min-h-screen ">
            <div className="banner-content">
            <Navbar/>
            </div>
            <div className='bg-[#ffffff] headers text-black font-extrabold text-[25px] py-8 px-[10px] mt-[30px] md:mt-[40px] text-center md:text-7xl' >
                <div data-aos="fade-up" >{processData?.title.toUpperCase()}</div>
            </div>
            <div className='h-[250px] md:h-[550px] border-none bgImg processHero '>
            </div>
        </div>
        <div className='flex p-5 pt-[20px]  bg-orange-500  flex-col md:flex-row'>
            <div className='  w-full md:w-2/3 px-4  border-transparent  md:border border-r-black '>
                <div data-aos="fade-up" className='headers text-[25px] md:text-5xl font-extrabold text-center w-full md:max-w-[500px] md:text-left' >
                {processData?.subTitle.toUpperCase()}
                </div>
            </div>
            <div data-aos="fade-down" className='content md:w-1/3 flex flex-col space-y-12 px-4'>
                <div className='text-[12px] md:text-base' >
                {processData?.subTileDesc}   
                </div>
                <Button variant='contained' class='content button-glitch'>Build Beyond Limits[+]</Button>
            </div>                    
        </div>


        <div style={{  backgroundColor: `${color}`}} >
            {
                processData?.process?.map((item, index) => 
                    <div key={index} style={{ color: `${txtcolor}`, backgroundColor: `${color}`, borderTop: `1px solid ${txtcolor}` }} data-aos="fade-up" className='flex p-5 pt-[20px]  flex-col-reverse md:flex-row-reverse transition ease-in duration-300'>
                        <div className='md:w-1/3 content flex flex-col justify-center  md:space-y-12 px-4 transition ease-in duration-300 py-7 '>
                            <div className='text-[15px] md:text-[19px] text-center md:text-left my-5' >
                                {item?.desc}
                                {
                                    item?.list?.map((dt) => 
                                        <div key={dt} className='py-3'>
                                            <u className='headers font-extrabold' >{dt?.title}</u> {dt?.desc}
                                        </div>
                                    )
                                }
                                <div>
                                    {/* <Button onClick={() => navigate(item?.link)} fullWidth variant='contained' className={color !== '#ffffff' ? 'btnWhite' : 'btnBlack'}>Our Stories [+]</Button> */}
                                </div>
                            </div>
                            {/* <div className='text-base' >
                        brandów. Wydobywamy je, zadając pytania więzi. W 247 tworzymy nagradza
                        DNA marek naszych Klientów. Docieramy do esencji i pełni potencjału brandów. Wydobywamy je, zadając pytania więzi. W 247 tworzymy nagradza
                    </div> */}
                        </div>
                        <div style={{ borderRight: `1px solid ${txtcolor}` }} className='md:w-2/3 px-4 flex flex-col space-y-5 transition ease-in duration-300 '>
                            <div className=' text-2xl break-words md:text-6xl font-extrabold headers' >
                            {item?.index} <br/> {item?.title.toUpperCase()}
                            </div>
                            <div className={`h-[250px] md:h-[550px] bgImg ${item.imgclass}`}>
                            </div>
                        </div>
                    </div>
                )

            }
        </div>


        
    </div>
  )
}

export default Process