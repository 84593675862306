import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import img from '../Assets/homeBanner.png'

function Projects({data}) {
    const navigate = useNavigate()
    return (
        <div className=' py-[30px] md:py-[80px] px-[30px] bg-white border border-white border-b-black'>
            <div data-aos="fade-down" className=" text-[25px] bold md:text-6xl py-2 headers">
                <div>{data?.title?.toUpperCase()}</div>
            </div>
            <div data-aos="fade-up" className=" md:flex justify-between flex-col md:flex-row items-center text-[10px] py-2  md:text-base ">
                <div className='max-w-[900px] content'>
                    {data?.desc}
                </div>
                <div className=" cursor-pointer text-[8px] text-thin md:text-[13px] py-2 md:py-4" >
                    <Button onClick={() => navigate(data?.link)} data-aos="fade-up" variant='contained' class='content button-glitch m-auto mt-5 ' >view more [+]</Button>
                </div>
            </div>
            <div data-aos="fade-up" className='grid grid-flow-row md:grid-flow-col gap-8 py-2'>
                {
                    data?.list?.slice(0, 3).map((item, index) =>
                    <div className='my-5 md:my-0 content'>
                        {
                            !item?.imgClass ? <div className={`h-[300px] overflow-hidden `}>
                                 <img className='object-cover h-80' src={img} alt="branding" />
                            </div>
                            :<div className={`h-[300px] bgImg ${item?.imgClass}`}>
                           
                        </div>}
                        <div className="text-[16px] md:text-xl py-2 md:py-2">
                            {item?.title}
                        </div>
                        <div className=" text-[10px] md:text-[13px] py-0">
                            {item?.desc}
                        </div>
                    </div>
                    )
                }
            </div>

        </div>
    )
}

export default Projects