export const processData = {
   title:"Beyond Imagination: Our Process for Crafting Immersive Experiences",
   subTitle:"Create Magic, Elevate brands experience",
   subTileDesc:"We're dedicated to crafting unforgettable experiences that captivate and inspire, and we're committed to exceeding expectations at every stage of the process. From concept to execution, we use our creativity and expertise to deliver solutions that resonate.",
    process:[
        {
            index:"01",
            title:"Discovery ",
            imgclass:"discoverImg",
            desc:"During the discovery phase, we work with our clients to define the scope, goals, and timeline of the project. This phase may include:",
            list:[{
                title:"Needs Assessment:",
                desc:"We work with our clients to identify their Metaverse needs and priorities, as well as any potential constraints or challenges.",
                },
                {
                    title:"Research and Analysis:",
                    desc:"We conduct research and analysis to identify the latest trends, best practices, and available technologies in the Metaverse space.",
                },
                {
                    title:"Project Planning:",
                    desc:"We develop a project plan that outlines the key milestones, deliverables, and timelines for the project."
                }
            ],
            link:"/solutions/media"            
        },
        {
            index:"02",
            title:"Design and Development ",
            imgclass:"designNDev",
            desc:"During the design and development phase, we create Metaverse experiences that meet our clients' needs and exceed their expectations. This phase may include:",
            list:[{
                title:"Concept Design:",
                desc:"We create a concept design for the Metaverse experience that aligns with our clients' business goals and values.",
            },
                {
                    title:"Prototyping:",
                    desc:"We build a functional prototype of the Metaverse experience to test its usability and functionality.",
                },
                {
                    title:"Development:",
                    desc:"We build the final Metaverse experience, including coding, graphics, animation, and sound."
                },
                {
                    title:"Quality Assurance:",
                    desc:"We rigorously test the Metaverse experience to ensure it is bug-free, performant, and meets our clients' quality standards."  
                }
            ],
            link:"/solutions/media"
        },
        {
            index:"03",
            title:"Launch and Deployment ",
            imgclass:"launchnDevImg",
            desc:"During the launch and deployment phase, we help our clients bring their Metaverse experiences to the market. This phase may include:",
            list:[{
                title:"Launch Planning:",
                desc:"We develop a launch plan that outlines the marketing and promotion activities, as well as the measurement and tracking of performance metrics.",
                },
                {
                    title:"Deployment:",
                    desc:"We deploy the Metaverse experience to the desired platform, such as a website or a Metaverse platform.",
                },
                {
                    title:"Maintenance and Support:",
                    desc:"We provide ongoing maintenance and support to ensure the Metaverse experience remains up-to-date, secure, and bug-free."
                }
            ],
            link:"/solutions/media"
        },
        {
            index:"04",
            title:"Consulting and Staffing ",
            imgclass:"consultingImg",
            desc:"During the consulting and staffing phase, we help our clients make informed decisions and leverage Metaverse talent. This phase may include:",
            list:[{
                title:"Consulting:",
                desc:"We provide consulting services to help our clients make informed decisions about their Metaverse projects.",
                },
                {
                    title:"Staffing:",
                    desc:"We provide staffing services to help our clients find the right Metaverse talent for their projects."
                }
            ],
            link:"/solutions/media"
        }
    ]
}

export const homeData = {
    title:"Elevate your brand with the power of",
    subTitle:"Embark on a journey of limitless possibilities with our Metaverse solutions and services. Discover a world beyond your imagination and explore the power of virtual reality. Join us on this adventure and unlock the full potential of Metaverse.",
    title2:"We are mokmeta",
    subTitle2:"We specialize in Metaverse and XR solutions that help businesses and organizations explore the potential of these technologies.Let us help you take advantage of these exciting new technologies and elevate your brand to new heights.",
    title3:"This is what we do ",
    subTitle3:"We're passionate about the power of immersive experiences. We believe that virtual worlds and digital realms have the potential to transform the way we interact with technology and with each other. That's why we specialize in creating cutting-edge solutions that bring these experiences to life.",
    title4:"Our Metaverse Services",
    subTitle4:"We believe that the metaverse represents the future of the internet, and we are committed to pushing the boundaries of what is possible in this exciting and rapidly evolving space. Our Metaverse services are a suite of products and services designed to provide users with access to a fully immersive and interactive digital",
    subTitle5:"Our Metaverse services are a suite of products and services designed to provide users with access to a fully immersive and interactive digital world.",
    list:[
        {
            title:"Metaverse Strategy",
            link:'/solutions/media',
            desc:"Our Metaverse strategy services are designed to help our clients understand the Metaverse landscape and develop a clear, actionable strategy for their Metaverse initiatives. Our services include:",
            list: [
                {
                    imgClass:'stratImg1',
                    title:"Metaverse Landscape Analysis:",
                    desc:"We analyze the Metaverse market and identify the key players, technologies, and trends that are shaping the Metaverse landscape."
                },
                {
                    imgClass:'stratImg2',
                    title:"Metaverse Opportunity Assessment:",
                    desc:"We help our clients identify opportunities in the Metaverse that align with their business goals and provide recommendations on how to capitalize on those opportunities."
                },
                {
                    imgClass:'stratImg3',
                    title:"Metaverse Roadmap Development:",
                    desc:"We work with our clients to develop a comprehensive roadmap for their Metaverse initiatives, including timelines, budgets, and resource requirements."
                },
                {
                    imgClass:'stratImg4',
                    title:"Metaverse Business Model Development:",
                    desc:"We help our clients develop business models that are tailored to the unique characteristics of the Metaverse, such as virtual goods, digital currencies, and decentralized governance."
                }
            ]
        },
        {
            title:"Metaverse Development",
            desc:"Our Metaverse development services are designed to help our clients build Metaverse experiences that are engaging, immersive, and scalable. Our services include:",
            link:'/solutions/features',
            list: [
                {
                    imgClass:'metdaDevImg1',
                    title:" Metaverse Experience Design:",
                    desc:"We work with our clients to design Metaverse experiences that are tailored to their audience and goals, including virtual worlds, games, social networks, and more."
                },
                {
                    imgClass:'metdaDevImg2',
                    title:"Metaverse Content Creation:",
                    desc:"We help our clients create high-quality Metaverse content, such as 3D models, animations, and sound effects, that enhance the immersive experience of their Metaverse initiatives."
                },
                {
                    imgClass:'metdaDevImg3',
                    title:"Metaverse Platform Development:",
                    desc:"We build Metaverse platforms that are scalable, secure, and interoperable with other Metaverse platforms, using cutting-edge technologies such as blockchain, Web3, and virtual reality."
                },
                {
                    imgClass:'metdaDevImg4',
                    title:" Metaverse API Development:",
                    desc:"We develop Metaverse APIs that allow our clients to integrate their Metaverse initiatives with other Metaverse platforms and services, creating a seamless user experience for their audience."
                }
            ]
        },
        {
            title:"Metaverse Consulting ",
            desc:"Our metaverse consulting services offer valuable support to businesses and organizations seeking to navigate the complex and rapidly evolving landscape of the metaverse. These services can help clients capitalize on the opportunities presented by the metaverse, and develop strategies to succeed in this exciting new digital frontier. Our services include:",
            link:'/solutions/strategy',
            list:[
                {
                    imgClass:'consultIMg1',
                    title:"Metaverse Technology Assessment:",
                    desc:"We help our clients assess the suitability of different Metaverse technologies for their needs."
                },
                {
                    imgClass:'consultIMg2',
                    title:"Metaverse Market Analysis:",
                    desc:"We provide market analysis and insights to help our clients make informed decisions about their Metaverse initiatives."
                },
                {
                    imgClass:'consultIMg3',
                    title:"Metaverse Legal and Regulatory Compliance: ",
                    desc:"We help our clients navigate the complex legal and regulatory landscape of the Metaverse."
                },
                {
                    imgClass:'consultIMg4',
                    title:"Metaverse platform selection:",
                    desc:" We help clients to choose the right metaverse platforms and technologies to achieve their objectives."
                },
                {
                    imgClass:'consultIMg5',
                    title:"Metaverse content creation and curation:",
                    desc:"We develop and curate high-quality, engaging content for use in the metaverse."
                },
                {
                    imgClass:'consultIMg6',
                    title:"Metaverse user experience design:",
                    desc:"We design immersive and engaging user experiences that are tailored to the specific needs and preferences of different user groups."
                }
            ]
        },
        {
            title:"Metaverse Talent on-demand",
            desc:"Our metaverse talend on-demand service provides clients with access to a flexible, scalable, and cost-effective talent pool that can be tapped into as needed, without the need to invest in full-time staff or costly training programs.Our services include:",
            link:'/solutions/metaverse',
            list:[
                {
                    imgClass:'onDemageIMg1',
                    title:"Metaverse Developers:",
                    desc:"We provide developers with expertise in Metaverse platforms and technologies, such as Unity, Unreal Engine, and Web3. These resourcese can also build and maintain metaverse platforms and applications."
                },
                {
                    imgClass:'onDemageIMg2',
                    title:"Metaverse Designers: ",
                    desc:"We provide designers with expertise in 3D modeling, animation, and user interface design."
                },
                {
                    imgClass:'onDemageIMg3',
                    title:"Metaverse User Experience Design: ",
                    desc:"We provide access to user experience designers who can create engaging and immersive user experiences that are tailored to the specific needs and preferences of different user groups. They can design intuitive interfaces, interactive features, and personalized experiences that enhance user engagement and satisfaction."
                },
                {
                    imgClass:'onDemageIMg4',
                    title:" Metaverse Content Creators: ",
                    desc:"We provide content creators with expertise in creating avatars, virtual goods, and immersive experiences. They can create multimedia content, such as videos, images, and audio, as well as immersive experiences, such as virtual environments, games, and simulations."
                },
                {
                    imgClass:'onDemageIMg5',
                    title:"Metaverse Marketing and Advertising: ",
                    desc:"We provide access to marketing and advertising professionals who can develop targeted campaigns to reach and engage with users in the metaverse. They can create and execute marketing strategies that increase brand awareness, drive user acquisition, and boost engagement and loyalty."
                }
            ]
        },
        {
            title:"Product Development",
            desc:"We build Metaverse products and experiences that engage and delight users. We also offer co-creation and co-innovation of products in close collaboration between businesses, developers, and users, as well as a focus on to ensure that the final product meets the evolving needs of the market.Our product development services include:",
            link:'/solutions/functionalities',
            list:[
                {
                    imgClass:'DevmntImg1',
                    title:"Metaverse Product Concepting:",
                    desc:"We work with our clients to develop new product concepts that are unique, innovative, and aligned with their brand."
                },
                {
                    imgClass:'DevmntImg2',
                    title:"Metaverse Product Design: ",
                    desc:" We create stunning Metaverse products and experiences that are user-friendly and visually appealing."
                },
                {
                    imgClass:'DevmntImg3',
                    title:"Metaverse Product Testing: ",
                    desc:"We rigorously test our Metaverse products to ensure they are bug-free, performant, and meet our clients' quality standards."
                },
                {
                    imgClass:'DevmntImg4',
                    title:"Metaverse Product Launch:",
                    desc:" We help our clients launch their Metaverse products and experiences to the market, including planning and executing marketing campaigns and measuring performance metrics."
                }
            ]
        },
    ]
}


export const useCaseData = {
    title:"unlocking the power of metaverse",
    subTitle:"Metaverse technology is rapidly transforming industries and revolutionizing the way we interact with each other and with the world around us. From virtual storefronts and immersive shopping experiences to virtual classrooms and online learning environments, the possibilities are endless. As we enter a new era of digital innovation, the power of metaverse use cases continues to expand, providing unparalleled opportunities for businesses and individuals alike.",
    title2:"Enter a new era of possibilities",
    subTitle2:"With the ability to create immersive and engaging experiences across a variety of industries, metaverse technology is unlocking new levels of creativity, connectivity, and collaboration. Explore the limitless potential of metaverse use cases.",
    list:[
        {
            index:'01',
            title:"Retail and E-commerce",
            desc:"The Metaverse offers exciting opportunities for retailers to create new, immersive shopping experiences that engage and delight customers.",
            list:[
                {
                    title:"Creating virtual storefronts and immersive shopping experiences that allow users to browse and purchase products in a more engaging and interactive way."
                },
                {
                    title:"Virtual try-on services that allow users to see how products look on them before they buy."
                },
                {
                    title:"Providing virtual product demonstrations and tutorials that help users understand how to use and benefit from products."
                }
            ],
            class:"EComm"
        },
        {
            index:'02',
            title:"Manufacturing",
            desc:"Manufacturing is an industry that stands to benefit greatly from the power of the Metaverse. With the help of immersive technologies, manufacturers can create new efficiencies and insights that were previously impossible.",
            list:[
                {
                    title:"Immersive digital twins to engineer complex products and processes that help manufacturers streamline their production processes and reduce costs."
                },
                {
                    title:"VR-based training to practice complex tasks and procedures in a safe and controlled environment."
                },
                {
                    title:"AR providing real-time line-of-sight information to workers on the factory floor."
                },
                {
                    title:"Remote collaboration using the Metaverse for design and prototyping"
                },
                {
                    title:"Creating virtual factory and supply chain management systems."
                }
            ],
            class:"manuFact"
        },
        {
            index:'03',
            title:"Real Estate and Construction",
            desc:"With the help of the Metaverse, real estate companies can offer new and innovative services that allow potential buyers and sellers to interact with properties in a more immersive and engaging way.",
            list:[
                {
                    title:"Offering virtual property tours that allow potential buyers to explore properties from the comfort of their own homes."
                },
                {
                    title:"Creating virtual staging services that allow sellers to showcase their properties in a more attractive and engaging way."
                },
                {
                    title:"Using augmented reality to help buyers visualize how properties would look with different furniture, finishes, and colors."
                },
                {
                    title:"Creating virtual project models that allow architects, engineers, and construction teams to collaborate in a shared virtual space and make real-time design decisions."
                }
            ],
            class:"realEst"
        },
        {
            index:'04',
            title:"Healthcare",
            desc:"The Metaverse has great potential in the healthcare industry for enhancing the way we deliver care, diagnose illnesses, and conduct research. Here are some of the use cases for the Metaverse in healthcare and diagnostics:",
            list:[
                {
                    title:"CVirtual reality therapy is an emerging field that uses VR technology to treat mental health disorders such as anxiety, phobias, and post-traumatic stress disorder. VR therapy can be used as an alternative to traditional therapy, providing patients with a safe and controlled environment to confront their fears."
                },
                {
                    title:"Virtual reality and augmented reality can be used to provide medical students and healthcare professionals with realistic simulations and training scenarios"
                },
                {
                    title:"The Metaverse can also be used to conduct medical research and clinical trials. Virtual environments can be used to simulate medical conditions, and to gather data on patient experiences and outcomes."
                }
            ],
            class:"healthCAre"
        },
        {
            index:'05',
            title:"Travel and Tourism",
            desc:"Metaverse technologies are transforming the way users plan and experience their trips, allowing them to explore destinations virtually and even book their trips in a more accessible way.",
            list:[
                {
                    title:"Offering virtual tours and experiences that allow users to explore travel destinations before they book."
                },
                {
                    title:"Creating virtual travel experiences and packages that provide users with a more immersive and engaging way to explore different parts of the world."
                },
                {
                    title:"Offering virtual events and festivals that allow users to experience cultural events and festivals from around the world."
                }
            ],
            class:"TourIsm"
        },
        {
            index:'06',
            title:"Education",
            desc:"The Metaverse offers exciting opportunities for retailers to create new, immersive shopping experiences that engage and delight customers.",
            list:[
                {
                    title:"Creating virtual classrooms and online learning environments that allow students to attend classes and interact with their teachers and classmates from anywhere in the world."
                },
                {
                    title:"Offering virtual simulations and training programs that allow students to practice real-world skills in a safe and controlled environment."
                },
                {
                    title:"Providing access to educational resources and materials through virtual libraries, museums, and other educational institutions."
                }
            ],
            class:"EDUcation"
        },
        {
            index:'07',
            title:"Fintech",
            desc:"Metaverse Fintech combines the benefits of Virtual Fintech with the opportunities offered by the Metaverse, providing a range of new use cases for financial services.",
            list:[
                {
                    title:"Virtual commerce where users can easily purchase and sell virtual goods and services within the Metaverse using digital currencies."
                },
                {
                    title:"Virtual banking services enable users to manage finances and transactions with virtual bankers, providing easy access to accounts, transactions, and financial advice."
                },
                {
                    title:"Create secure digital identities and manage them for financial transactions in the Metaverse, including applying for loans, opening bank accounts, and performing other financial activities."
                }
            ],
            class:"FinTeCH"
        }
    ]
}

export const solutionData = {
    title:"Experience the Future Now",
    subTitle:"Explore Our Metaverse Services and Transform Your Digital Presence",
    desc:"Welcome to the future of digital experiences. Our Metaverse Services offer innovative solutions to transform the way you engage with your customers, employees, and partners. Discover a new world of possibilities where virtual and physical environments merge seamlessly to create immersive and interactive experiences.",
    title2:"Innovative Metaverse Solutions for a",
    subTitle2:"From gaming and entertainment to education, real estate, retail, healthcare, advertising, and marketing, our Metaverse Services are designed to help you unlock the full potential of the metaverse. Join us on this exciting journey and explore the endless possibilities of the digital world.",
    list:[
        {
            index:"01",
            title:" Strategy",
            subTitle:"Analysis, Assessment, Roadmap, Business Model",
            desc:"Our Metaverse strategy services are designed to help our clients understand the Metaverse landscape and develop a clear, actionable strategy for their Metaverse initiatives.",
            link:"/solutions/media",
            class:"staterGy"
        },
        {
            index:"02",
            title:" Development",
            subTitle:"Design, Platform Development, Content, APIs",
            desc:"Our Metaverse development services are designed to help our clients build Metaverse experiences that are engaging, immersive, and scalable",
            link:"/solutions/features",
            class:"devLopment"
        },
        {
            index:"03",
            title:" Consulting",
            subTitle:"Technology, Market Analysis, Compliance, Platform selection",
            desc:"Our metaverse consulting services offer valuable support to businesses and organizations seeking to navigate the complex and rapidly evolving landscape of the metaverse. These services can help clients capitalize on the opportunities presented by the metaverse, and develop strategies to succeed in this exciting new digital frontier.",
            link:"/solutions/strategy",
            class:"ConstLting"
        },
        {   
            index:"04",
            title:" Talent on-demand",
            subTitle:"Developers, Designers, Content creators, Marketing Professionals",
            desc:"Our Metaverse consulting services are designed to help our clients understand the Metaverse landscape and develop a clear, actionable strategy for their Metaverse initiatives.",
            link:"/solutions/metaverse",
            class:"DemaNd"
        },
        {
            index:"05",
            title:" Development",
            subTitle:"Concept, Design, Testing, Launch",
            desc:"We build Metaverse products and experiences that engage and delight users. We also offer co-creation and co-innovation of products in close collaboration between businesses, developers, and users, as well as a focus on to ensure that the final product meets the evolving needs of the market",
            link:"/solutions/functionalities",
            class:"deveLopement"
        }
    ]
}

export const capabilitiesData = {
    title:"transform your business for the virtual era",
    subTitle:"The virtual era presents a unique opportunity for businesses to reach new audiences and markets. Let us help you navigate the digital landscape and unlock new opportunities for your business in the virtual era.",
    title2:"our capabilities",
    subTitle2:"we specialize in providing expert guidance, solutions, and services to businesses and individuals looking to establish and succeed in the metaverse. Our team of experienced professionals brings a deep understanding of the latest metaverse trends and technologies to help our clients design, build, and launch virtual worlds and experiences that align with their goals.",
    list:[
        {
            index:"01",
            title:"AR/VR/XR",
            class:"ARvr",
            subTitle:"We specialize in providing cutting-edge AR/VR/XR technologies to our clients. Whether you're looking to create an immersive virtual world, enhance your existing products with augmented reality features, or explore the potential of extended reality technologies, we have the expertise and experience to help you achieve your goals. Our team of experts can guide you through the entire process, from ideation and design to development and deployment, ensuring that you get the most out of these exciting and rapidly evolving technologies.",
        },
        {
            index:"02",
            title:"Digital Twins",
            class:"DigTwins",
            subTitle:"We build digital twins for clients - virtual replicas of physical objects or systems that provide deep insights, inform decision-making, and optimize performance. Our experts use data, sensors, and modeling tools to create accurate and reliable digital twins for a wide range of applications, from industrial equipment and infrastructure to buildings and entire cities.",
        },
        {
            index:"03",
            title:"Virtual Worlds",
            class:"virtWorld",
            subTitle:"We offer virtual world solutions that allow our clients to create immersive and engaging environments for their audiences. Our virtual world solutions have a wide range of applications, from enhancing training and simulation experiences to creating engaging marketing campaigns and revolutionizing customer interactions. Whether you're looking to create a virtual showroom, host a virtual event, or build an entirely new digital experience, our virtual world solutions can help you to achieve your goals.",
        },
        {
            index:"04",
            title:"3D Visualizations",
            class:"ThreedVisual",
            subTitle:"We offer 3D visualizations that bring virtual environments to life. We create immersive and realistic 3D visualizations that can be used to design, plan, and visualize virtual worlds. From realistic environments and objects to lifelike avatars and interactive elements, our 3D visualizations can help you",
        }
    ]
}

export const aboutdata = {
    title:"Metaverse Mavericks: Pioneering the Future of Digital Interaction",
    sideText1:"Embracing Change and Fueling Innovation",
    sidetext2:"We believe that the metaverse represents a fundamental shift in the way we live, work, and play. As pioneers in this new digital landscape, we are dedicated to helping our clients unlock the endless potential of the metaverse to create new forms of social interaction, enhance education and learning, and revolutionize the way we do business.",
    title2:"We are on a mission to fuel digital innovation by bringing immersive interactions to life",
    sideText3:"Boldly Pioneering the Future of Digital Interaction and Innovation",
    sideText4:"At our core, we at Mokmeta are driven by a mission to revolutionize the way people interact with technology. We empower individuals and businesses to explore the possibilities of the metaverse, create new forms of social interaction, education, and commerce, and transform the way people connect with each other and with technology. Our goal is to fuel digital innovation and create a more immersive, inclusive, and interactive digital world that empowers our clients to achieve their full potential.",
    Values:[
        {
            title:"Innovation",
            desc:"We are committed to pushing the boundaries of what is possible and exploring new technologies to fuel digital innovation.",
        },{
            title:"Collaboration",
            desc:"We believe that the best results are achieved through open communication, teamwork, and shared knowledge",
        },{
            title:"Excellence",
            desc:"We hold ourselves to the highest standards of quality and strive to deliver exceptional results to our clients.",
        },{
            title:"Creativity",
            desc:"We approach every challenge with a creative mindset, leveraging our expertise and imagination to craft unique solutions.",
        },{
            title:"Integrity",
            desc:"We believe in conducting business with honesty, transparency, and integrity, building trust and long-term relationships with our clients."
        }
    ],
    list:[
        {
            index:"01",
            title:"Discovery Phase work with our clients to define the scope, goals, and timeline ",
            desc:"Want to create something incredible ? Say hello to the team and let's make it happen.",
            list:[{
                title:"Needs Assessment:",
                desc:"We work with our clients to identify their Metaverse needs and priorities, as well as any potential constraints or challenges.",
                },
                {
                    title:"Research and Analysis:",
                    desc:"We conduct research and analysis to identify the latest trends, best practices, and available technologies in the Metaverse space.",
                },
                {
                    title:"Project Planning:",
                    desc:"We develop a project plan that outlines the key milestones, deliverables, and timelines for the project."
                }
            ],
            link:"/solutions/media"            
        },  
    ]
}

export const serviceData = [
    {
        index:"01",
        imgclass:"staterGy",
        title: "Metaverse Strategy",
        heroImg2:"metaStrategy2",
        subTitle: "Our Metaverse Strategy services provide clients of all sizes and industries develop a comprehensive and actionable plan for their metaverse initiatives.",
        desc: "The metaverse is a vast digital landscape. Let us guide you through it with our tailored strategy consulting services.",
        subtitle2:"Explore the metaverse with confidence.",
        desc2:"Our Metaverse Strategic Planning services are designed to help businesses and individuals unleash the full potential of the metaverse. Our experienced consultants will work with you to identify your key objectives, assess your digital footprint, and develop a customized strategy that leverages the unique capabilities of the metaverse to help you achieve your goals.",
        list: [
            {
                imgClass:'stratImg1',
                title:"Metaverse Landscape Analysis:",
                desc:"We analyze the Metaverse market and identify the key players, technologies, and trends that are shaping the Metaverse landscape."
            },
            {
                imgClass:'stratImg2',
                title:"Metaverse Opportunity Assessment:",
                desc:"We help our clients identify opportunities in the Metaverse that align with their business goals and provide recommendations on how to capitalize on those opportunities."
            },
            {
                imgClass:'stratImg3',
                title:"Metaverse Roadmap Development:",
                desc:"We work with our clients to develop a comprehensive roadmap for their Metaverse initiatives, including timelines, budgets, and resource requirements."
            },
            {
                imgClass:'stratImg4',
                title:"Metaverse Business Model Development:",
                desc:"We help our clients develop business models that are tailored to the unique characteristics of the Metaverse, such as virtual goods, digital currencies, and decentralized governance."
            }
        ]
    },
    {
        index:"02",
        imgclass:"devLopment",
        title: "Metaverse Development",
        heroImg2:"metadev2",
        subTitle: "Enter the immersive world of the metaverse and captivate your audience with our tailored development services.",
        desc: "To truly succeed in the metaverse, Mokmeta as a partner can help you navigate the complex and rapidly evolving landscape of metaverse development.",
        subtitle2:"Empower your metaverse vision with impact",
        desc2:"Our Metaverse development services are designed to help our clients build Metaverse experiences that are engaging, immersive, and scalable. Our team of experts has the knowledge and experience necessary to turn your metaverse strategy into a reality. From concept to deployment, we can help you build the immersive and interactive experiences that will engage your audience and elevate your brand.",
        list: [
            {
                imgClass:'metdaDevImg1',
                title:" Metaverse Experience Design:",
                desc:"We work with our clients to design Metaverse experiences that are tailored to their audience and goals, including virtual worlds, games, social networks, and more."
            },
            {
                imgClass:'metdaDevImg2',
                title:"Metaverse Content Creation:",
                desc:"We help our clients create high-quality Metaverse content, such as 3D models, animations, and sound effects, that enhance the immersive experience of their Metaverse initiatives."
            },
            {
                imgClass:'metdaDevImg3',
                title:"Metaverse Platform Development:",
                desc:"We build Metaverse platforms that are scalable, secure, and interoperable with other Metaverse platforms, using cutting-edge technologies such as blockchain, Web3, and virtual reality."
            },
            {
                imgClass:'metdaDevImg4',
                title:" Metaverse API Development:",
                desc:"We develop Metaverse APIs that allow our clients to integrate their Metaverse initiatives with other Metaverse platforms and services, creating a seamless user experience for their audience."
            }
        ]
    },
    {
        index:"03",
        imgclass:"ConstLting",
        heroImg2:"metaconsult2",
        title: "Metaverse Consulting",
        subTitle: 'Transform your brand and captivate your audience with our comprehensive Metaverse Consulting services, built on a foundation of deep industry expertise and cutting-edge innovation',
        desc: "From concept to deployment, we are your trusted partner in the metaverse, working with you every step of the way to help you achieve your goals and drive meaningful ROI.",
        subtitle2:"Step into the future of digital experiences",
        desc2:"Our metaverse consulting services offer valuable support to businesses and organizations seeking to navigate the complex and rapidly evolving landscape of the metaverse. These services can help clients capitalize on the opportunities presented by the metaverse, and develop strategies to succeed in this exciting new digital frontier.",
        list:[
            {
                imgClass:'consultIMg1',
                title:"Metaverse Technology Assessment:",
                desc:"We help our clients assess the suitability of different Metaverse technologies for their needs."
            },
            {
                imgClass:'consultIMg2',
                title:"Metaverse Market Analysis:",
                desc:"We provide market analysis and insights to help our clients make informed decisions about their Metaverse initiatives."
            },
            {
                imgClass:'consultIMg3',
                title:"Metaverse Legal and Regulatory Compliance: ",
                desc:"We help our clients navigate the complex legal and regulatory landscape of the Metaverse."
            },
            {
                imgClass:'consultIMg4',
                title:"Metaverse platform selection:",
                desc:" We help clients to choose the right metaverse platforms and technologies to achieve their objectives."
            },
            {
                imgClass:'consultIMg5',
                title:"Metaverse content creation and curation:",
                desc:"We develop and curate high-quality, engaging content for use in the metaverse."
            },
            {
                imgClass:'consultIMg6',
                title:"Metaverse user experience design:",
                desc:"We design immersive and engaging user experiences that are tailored to the specific needs and preferences of different user groups."
            }
        ]
    },
    {
        index:"04",
        imgclass:"DemaNd",
        heroImg2:"metaStaff2",
        title: "Metaverse Talent on-demand",
        subTitle: 'Accelerate your metaverse initiatives with our flexible and scalable talent solutions, providing you with the right expertise and resources to meet your unique needs and exceed your goals.',
        desc: "Partner with our metaverse talent experts who are passionate about innovation, collaboration, and delivering exceptional results and take your immersive experiences to the next level",
        subtitle2:"Stay ahead of the competition",
        desc2:"Our metaverse talent on-demand service provides clients with access to a flexible, scalable, and cost-effective talent pool that can be tapped into as needed, without the need to invest in full-time staff or costly training programs.",
        list:[
            {
                imgClass:'onDemageIMg1',
                title:"Metaverse Developers:",
                desc:"We provide developers with expertise in Metaverse platforms and technologies, such as Unity, Unreal Engine, and Web3. These resourcese can also build and maintain metaverse platforms and applications."
            },
            {
                imgClass:'onDemageIMg2',
                title:"Metaverse Designers: ",
                desc:"We provide designers with expertise in 3D modeling, animation, and user interface design."
            },
            {
                imgClass:'onDemageIMg3',
                title:"Metaverse User Experience Design: ",
                desc:"We provide access to user experience designers who can create engaging and immersive user experiences that are tailored to the specific needs and preferences of different user groups. They can design intuitive interfaces, interactive features, and personalized experiences that enhance user engagement and satisfaction."
            },
            {
                imgClass:'onDemageIMg4',
                title:" Metaverse Content Creators: ",
                desc:"We provide content creators with expertise in creating avatars, virtual goods, and immersive experiences. They can create multimedia content, such as videos, images, and audio, as well as immersive experiences, such as virtual environments, games, and simulations."
            },
            {
                imgClass:'onDemageIMg5',
                title:"Metaverse Marketing and Advertising: ",
                desc:"We provide access to marketing and advertising professionals who can develop targeted campaigns to reach and engage with users in the metaverse. They can create and execute marketing strategies that increase brand awareness, drive user acquisition, and boost engagement and loyalty."
            }
        ]
    },
    {
        index:"05",
        imgclass:"deveLopement",
        heroImg2:"metpProd2",
        title: "Product Development",
        subTitle: 'Bring your metaverse product ideas to life with our comprehensive product development services, delivering innovative solutions that captivate your audience and drive meaningful engagement',
        desc: "Stay ahead of the curve with our cutting-edge metaverse product development services, leveraging the latest technologies and industry insights to create innovative solutions that set your brand apart from the crowd.",
        subtitle2:"Build a strong and effective digital presence",
        desc2:"Collaborate with our experienced team of developers and designers to create metaverse products that are not only visually stunning, but also functional and user-friendly, delivering a seamless and engaging experience for your audience",
        list:[
            {
                imgClass:'DevmntImg1',
                title:"Metaverse Product Concepting:",
                desc:"We work with our clients to develop new product concepts that are unique, innovative, and aligned with their brand."
            },
            {
                imgClass:'DevmntImg2',
                title:"Metaverse Product Design: ",
                desc:" We create stunning Metaverse products and experiences that are user-friendly and visually appealing."
            },
            {
                imgClass:'DevmntImg3',
                title:"Metaverse Product Testing: ",
                desc:"We rigorously test our Metaverse products to ensure they are bug-free, performant, and meet our clients' quality standards."
            },
            {
                imgClass:'DevmntImg4',
                title:"Metaverse Product Launch:",
                desc:" We help our clients launch their Metaverse products and experiences to the market, including planning and executing marketing campaigns and measuring performance metrics."
            }
        ]
    }
]

export default processData