import React from 'react'
import { Grid } from '@mui/material'
import img from '../Assets/statue.jpg'

function ServicePhone({index,data}) {
  return (
    <div className=' border border-t-black p-[20px] '>
        {/* <Navbar/> */}
        <Grid container xs={12}>
            <Grid item xs={12} >
                <div className='flex flex-col text-2xl bold py-2' >
                <span className='mb-4' >{index+1}</span>
                </div>
                <div className={` ${data?.imgClass} h-[25vh] w-full object-fill bgImg mx-auto transition duration-500  ease-in-out`} ></div>
            </Grid>
            <Grid sx={{py:4}} item xs={12}>
                <Grid container xs={12}>
                    <Grid xs={12} item>
                        <div className='px-4 bold my-5 text-center text-4xl headers'>{data?.title?.toUpperCase()}</div>
                    </Grid>
                    <Grid xs={12} item>
                        <div className='px-4 text-xl text-justify content'>{data?.desc}</div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>
  )
}

export default ServicePhone