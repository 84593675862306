import { Button, Grid } from '@mui/material'
import { useEffect } from 'react'
import Footer from '../Components/Footer/Footer'
import Navbar from '../Components/Navbar'
import SectionTemplate from '../Components/SectionTemplate'
import { serviceData } from '../Data/data'

function Media() {
 
  return (
    <div className='bg-[#ffffff] overflow-hidden'>
        <Navbar/>
        <SectionTemplate data={serviceData[0]}/>
        

    </div>
  )
}

export default Media