import React, { useEffect } from 'react'
import Footer from '../Components/Footer/Footer'
import Navbar from '../Components/Navbar'
import SectionTemplate from '../Components/SectionTemplate'
import { serviceData } from '../Data/data'

function Stratergy() {
  
  return (
    <div className='bg-[#ffffff] overflow-hidden'>
        <Navbar/>
        <SectionTemplate data={serviceData[2]}/>
        
    </div>
  )
}

export default Stratergy