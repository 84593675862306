import { display } from '@mui/system';
import React, { useEffect, useState } from 'react'
import bing from '../../../Assets/statue.jpg'

function Hoverer({title,image}) {

    const [isHovering, setIsHovering] = useState(false);
    const [position, setPosition] = useState({
        x: "",
        y: "",
      });

    const handleMouseOver = () => {
        setIsHovering(true);
      };
    
      const handleMouseOut = () => {
        setIsHovering(false);
    };
    
      const handleMouseMove = (e) => {
        setTimeout(() => {
          setPosition({
          x: e.clientX + 80,
            y: e.clientY - 250,
          });
        }, 30);
      };
    
      useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        return () => {
          window.removeEventListener("mousemove", handleMouseMove);
        };
      }, []);
  return (
    <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}  >
        <div className={ !isHovering  ? 'bgImg h-[45vh] md:h-[70vh] w-[200px] md:w-[400px] scale-0 rotate-0 duration-1000  ease-in-out ' : ` rotate-0 h-[45vh] md:h-[70vh] w-[200px] md:w-[400px] transition scale-125 duration-1000  ease-in-out`}
        style={{ position:'fixed', left: `${position.x}px`, top: `${position.y}px` 
        // ,display: !isHovering ? 'none' : 'block',
         ,zIndex:5  }}
      >
        <div className={`bgImg ${image} object-fill h-full w-full `} ></div>
      </div>
        <div className=' cursor-pointer bg-orange-500 border border-orange-500 border-b-gray-800 text-black text-base md:text-7xl text-center py-8  font-extrabold w-full headers hover:text-white transition duration-500 ' >
         <div className={`${isHovering && 'z-20 relative'} `} >{title} </div>  </div>    
         
    </div>
  )
}

export default Hoverer