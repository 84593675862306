import React, { useEffect } from 'react'
import Footer from './Footer/Footer'
import FootForm from './Footer/FootForm'
import Navbar from './Navbar'

function Contact() {
  
  return (
    <div className='pt-10 bg-[#ffffff19]' >
        {/* <div className='sm:px-12 p-8 pt-0 text-3xl' >Contact Us</div> */}
        <Navbar/>
        
    </div>
  )
}

export default Contact