import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import Navbar from '../Components/Navbar'
import { useCaseData } from '../Data/data'
import { useNavigate } from 'react-router-dom'

function Usecase() {
    const navigate = useNavigate()
    const [color, setcolor] = useState("#ffffff")
    const [txtcolor, settxtcolor] = useState("#000000")
    
    useEffect(() => {
        const changeColor = () => {
            if (window.scrollY >= 1200 && window.scrollY <= 4000 ) {
              setcolor('#000000');
              settxtcolor('#ffffff');
            } 
            
            else {
              setcolor('#ffffff');
              settxtcolor('#000000');
          }
        };
        window.addEventListener('scroll', changeColor);
      }, []);
    
  return (
    <div className='bg-[#ffffff]' >
        <Navbar/>
        <div className='flex my-5 px-5 pt-[50px] flex-col  md:flex-row'>
            <div className='md:w-2/3  px-4 grid grid-cols-2 gap-5  md:border border-transparent border-r-gray-500 '>
                <div className='' >
                    <div className='h-[150px] md:h-full  bgImg usecase1'>
                    </div>
                    {/* <div className='flex md:items-center md:space-x-6 p-2 flex-col  md:flex-row'>
                        <div>Introducing</div>
                        <div className='text-[12px] md:text-lg'>Look inside out house</div>
                    </div> */}
                </div>
                <div className='' >
                    <div className='h-[150px] md:h-full  bgImg usecase2'>
                    </div>
                    {/* <div className='flex md:items-center md:space-x-6 p-2 flex-col  md:flex-row'>
                        <div>Introducing</div>
                        <div className='text-[12px] md:text-lg'>Look inside out house</div>
                    </div> */}
                </div>
                <div className='' >
                    <div className='h-[150px] md:h-full  bgImg usecase3'>
                    </div>
                    {/* <div className='flex md:items-center md:space-x-6 p-2 flex-col  md:flex-row'>
                        <div>Introducing</div>
                        <div className='text-[12px] md:text-lg'>Look inside out house</div>
                    </div> */}
                </div>
                <div className='' >
                    <div className='h-[150px] md:h-full  bgImg usecase4'>
                    </div>
                    {/* <div className='flex md:items-center md:space-x-6 p-2 flex-col  md:flex-row'>
                        <div>Introducing</div>
                        <div className='text-[12px] md:text-lg'>Look inside out house</div>
                    </div> */}
                </div>
            </div>
            <div className='md:w-1/3 flex flex-col justify-between my-5 space-y-4 md:space-y-12 px-4'>
                <div data-aos="fade-up" className='font-extrabold headers text-center text-bold text-[20px] md:text-7xl md:text-left' >
                    {useCaseData?.title?.toUpperCase()}
                </div>
                <div className='flex flex-col' >
                    <div data-aos="fade-up" className='content text-[13px] md:text-base' >
                        {useCaseData?.subTitle}
                    </div>
                    <Button data-aos="fade-up" variant='contained' class='content button-glitch m-auto mt-5 ' onClick={() => navigate('/contact')} >Lets Innovate Together [+]</Button>
                </div>
            </div>                    
        </div>
        <div className='flex mt-5 p-5 pt-[20px] bg-orange-500 flex-col md:flex-row'>
            <div className='  w-full md:w-2/3 px-4  border-orange-500  md:border border-r-black '>
                <div data-aos="fade-up" className='font-extrabold headers text-[25px] md:text-5xl text-center w-full md:max-w-[500px] md:text-left' >
                    {useCaseData?.title2?.toUpperCase()}
                </div>
            </div>
            <div data-aos="fade-down" className='md:w-1/3 flex flex-col justify-between md:space-y-12 px-4 content'>
                <div className='text-[12px] md:text-base my-5' >
                    {useCaseData?.subTitle2}
                </div>
                <Button data-aos="fade-up" variant='contained' class='content button-glitch w-fit m-auto my-5' onClick={() => navigate('/contact')} >Talk to Us[+]</Button>
            </div>                    
        </div>
        <div style={{ color: `${txtcolor}`, backgroundColor: `${color}` }}  >
        {
            useCaseData?.list?.map((item, index) => 
                <div style={{ color: `${txtcolor}`, backgroundColor: `${color}` }}  data-aos="fade-up" className={index %2 === 0 ? 'flex p-5 pt-[20px] border border-transparent border-t-gray-500  flex-col-reverse md:flex-row':
                    'flex p-5 pt-[20px] border border-transparent border-t-gray-500  flex-col-reverse md:flex-row-reverse'}>
                    <div className='md:w-1/3 flex flex-col justify-between  md:space-y-12 px-4'>
                        <div className='text-[25px] md:text-5xl text-center md:text-left my-5 break-words font-extrabold headers' >
                            {item?.title?.toUpperCase()}
                        </div>
                        <div className='text-base text-justify' >
                            {item?.desc}
                            <ul className='pt-5 '>
                                
                            {
                                item?.list?.slice(0,3).map((item, index) =>
                                <li className='border border-transparent border-t-gray-600 py-3 ' key={index}>
                                        <div className='text-[12px] md:text-base' >{item?.title}</div>
                                    </li>
                                )
                            }
                            </ul>
                        </div>
                    </div>
                    <div className={ index%2 ===0 ? `md:w-2/3 px-4 flex flex-col space-y-5 border border-transparent ${txtcolor==='#000000' ? 'border-l-gray-500 ' : 'border-l-white' }` : 
                        `md:w-2/3 px-4 flex flex-col space-y-5 border border-transparent ${txtcolor==='#000000' ? 'border-r-gray-500 ' : 'border-r-white' }`}>
                        <div className='text-5xl max-w-md font-extrabold headers' >
                            {item?.index}
                        </div>
                        <div className={`h-[250px] md:h-[550px] bgImg ${item.class}`}>
                        </div>
                    </div>
                </div>
        )}       
        </div>
        
    </div>
  )
}

export default Usecase