import React from 'react'
import Footer from '../Components/Footer/Footer'
import Navbar from '../Components/Navbar'
import SectionTemplate from '../Components/SectionTemplate'
import { serviceData } from '../Data/data'

function Functionalities() {
  return (
    <div className='bg-[#ffffff] overflow-hidden'>
        <Navbar/>
        <SectionTemplate data={serviceData[4]}/>
        
    </div>
  )
}

export default Functionalities