import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css';
import { useEffect, useRef } from 'react';
import Lenis from '@studio-freight/lenis'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import Solutions from './Pages/Solutions';
import Capabilities from './Pages/Capabilities';
import Usecase from './Pages/Usecase';
import Media from './Pages/Media';
import Features from './Pages/Features';
import Stratergy from './Pages/Stratergy';
import Metaverse from './Pages/Metaverse';
import Contact from './Components/Contact';
import Process from './Pages/Process';
import Functionalities from './Pages/Functionalities';
import Footer from './Components/Footer/Footer';
import AboutUs from './Pages/AboutUs';
import './Assets/BespokeStencil-Bold.otf'
import ScrollToTop from './Components/ScrollToTop';

export default function App() {
  
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const lenis = new Lenis({
        duration: 1.4,
        easing: (t) => Math.min(1, 1.1 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
        direction: "vertical", // vertical, horizontal
        gestureDirection: "vertical", // vertical, horizontal, both
        smooth: true,
        mouseMultiplier: 0.6,
        smoothTouch: false,
        touchMultiplier: 2,
        infinite: false,
      });

      //get scroll value
      lenis.on("scroll", ({ scroll, limit, velocity, direction, progress }) => {
        // console.log({ scroll, limit, velocity, direction, progress });
      });

      function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
      }

      requestAnimationFrame(raf);
    }
  },[]);
  // const { pathname } = useLocation() // With react-router

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [pathname])



  return (
    <div className='app'>
      <div className='z-30 relative' >
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/capabilities" element={<Capabilities />} />
          <Route path="/usecase" element={<Usecase />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/process" element={<Process />} />
          <Route path="/solutions/media" element={<Media />} />
          <Route path="/solutions/features" element={<Features />} />
          <Route path="/solutions/strategy" element={<Stratergy />} />
          <Route path="/solutions/metaverse" element={<Metaverse />} />
          <Route path="/solutions/functionalities" element={<Functionalities />} />
          <Route path="*" element={<Navigate to={'/'} />} />
        </Routes>
      </div>
    <Footer />
    </div>
  )
}