import { Button, TextField } from '@mui/material'
import React from 'react'
import footIMg from '../../Assets/logo192.png'

function FootForm() {
    return (
        <div className='footerBg bgImg flex flex-col h-full text-black sm:px-12 p-2 md:p-8 content '>
            <div data-aos="fade-up" className="text-white flex items-center justify-center text-center w-full md:text-7xl headers md:my-24 md:mt-40 ">
                <div class="container flex items-center justify-center">
                    <p class="glitchh flex items-center justify-center">
                        <span aria-hidden="true">LET'S GO VIRTUAL</span>
                            LET'S GO VIRTUAL
                        <span aria-hidden="true">LET'S GO VIRTUAL</span>
                    </p>
                </div>
            </div>
            <div className='bg-[#ffffffd8] p-5 md:p-8 md:px-14 rounded-[40px] max-w-4xl mx-auto my-10' >
                <p data-aos="fade-down" className='text-left px-6 font-extrabold text-xl mt-16 md:text-6xl' >GET IN TOUCH</p>
                <div data-aos="fade-up" className='flex flex-col md:flex-row items-center' >
                    <div>
                        <p className='font-medium m-1 md:m-6 text-justify' >
                            At Mokmeta, our team of experienced metaverse consultants is dedicated to staying on the cutting edge of this emerging technology and providing our clients with tailored solutions that meet their unique needs.
                            Contact us today to learn more about our consulting services and how we can help you unlock your full potential in the metaverse.
                        </p>
                    </div>
                    <div className='hidden md:flex items-center justify-center m-8' >
                        <img className='w-56 object-contain' src={footIMg} alt="" />
                    </div>
                </div>
                <div className='my-10 m-5 text-2xl font-bold'>
                    <div className='my-6  ' >{(" name").toUpperCase()}</div>
                    <TextField className='outline-gray-600' variant='standard' fullWidth />
                    <div className='my-6' >{(" email").toUpperCase()}</div>
                    <TextField className='outline-gray-600' variant='standard' type='email' fullWidth />
                    <div className='my-6' >{(" phone ").toUpperCase()}</div>
                    <TextField className='outline-gray-600' variant='standard' type='' fullWidth />
                    <div className='my-6' >{(" message").toUpperCase()}</div>
                    <TextField className='outline-gray-600' variant='standard' multiline rows={8} fullWidth />
                    <div className='flex'>
                        <Button variant='contained' class='content button-glitch m-auto mt-5 ' fullWidth  >Lets Build Something great [+]</Button>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" className='md:w-4/5 max-w-4xl text-white mx-auto  space-y-6 md:space-y-0 pr-3 pb-3 flex flex-col md:flex-row justify-between ' >
                <div>
                    <div className="font-extrabold">Email</div>
                    <div>hello@mokmeta.io</div>
                </div>
                <div>
                    <div className="font-extrabold">Phone</div>
                    <div>+91  8317615264</div>
                </div>
                <div>
                    <div className="font-extrabold">Contact</div>
                    <div>
                        4th Floor, Gowri Arcade,Jayabheri Enclave,
                        <br />
                        Gachibowli, Hyderabad,Telangana - 500032
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FootForm