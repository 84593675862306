import { Button, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { capabilitiesData } from '../Data/data'
import Navbar from '../Components/Navbar'

function Capabilities() {
    
  return (
    <div >
        <div className="min-h-fit bg-[#e3dfdc] md:min-h-screen ">
            <div className="banner-content">
            <Navbar/>
            </div>
            <div className='h-[250px] md:h-[550px] bgImg capBannner'>
                </div>
            <div className='bg-black text-white text-[25px] py-8 px-[10px] text-center md:text-7xl' >
                <div data-aos="fade-up" className='w-[90%] m-auto headers' >{capabilitiesData?.title?.toUpperCase()}</div>
                <div data-aos="fade-up"className='text-[10px] w-3/4  mx-auto my-7 md:text-base max-w-[700px] content' >
                    {capabilitiesData?.subTitle}
                </div>
                <div className='flex' >
                    <Button data-aos="fade-up" variant='contained' class='content button-glitch m-auto mt-5 '>Unleash Your potential [+]</Button>
                </div> 
            </div>
        </div>
        <div className=' text-black flex p-5 pt-[20px]  border border-black border-y-orange-500 bg-orange-500  flex-col md:flex-row'>
            <div className='  w-full md:w-1/3 px-4 md:py-20  border-transparent  md:border border-r-black '>
                <div data-aos="fade-up" className='text-[25px] md:text-5xl text-center w-full md:pr-5 md:max-w-[500px] md:text-left headers' >
                    {capabilitiesData?.title2?.toUpperCase()}
                </div>
            </div>
            <div className='md:w-2/3 md:py-20  flex flex-col space-y-12 px-4 content'>
                <div data-aos="fade-up" className='text-[12px] md:text-base' >
                    {capabilitiesData?.subTitle2}
                </div>
            </div>    
        </div>
        {
            capabilitiesData?.list?.map((item, index) =>
                <div >
                    <Grid xs={12} sm={12} md={12} xl={12} container className='bg-black text-white cursor-pointer py-5 hover:bg-green-300 hover:text-black ease-in duration-300 content' >
                        <Grid item xs={12} sm={12} md={4} xl={3} className='p-4 py-6 min-h-32 text-4xl md:pl-32' >
                            <div className={`h-60 w-60 bgImg ${item.class} `}></div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} xl={5} className=' flex align-center p-4 py-6 min-h-32 headers md:text-[45px]' >
                            <span className=' m-auto' >{item?.title.toUpperCase()}</span>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} xl={4} className='text-justify p-4 py-6 min-h-32 text-base ' >
                            {item?.subTitle}
                        </Grid>
                    </Grid>
                </div>
        )}
    </div>
  )
}

export default Capabilities