import { Grid } from '@mui/material'
import { useState } from 'react';
import img from '../Assets/statue.jpg'
import Navbar from './Navbar';
// import './service.css'

function Service({data ,index ,col,txcol}) {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
      };
    
      const handleMouseOut = () => {
        setIsHovering(false);
      };
  return (
    <div 
    // style={{ color : `${txtcolor}`, backgroundColor: `${color}`,borderTop: `1px solid ${txtcolor}`}} 
    className=' border border-t-black p-[20px] '>
        {/* <Navbar/> */}
        <Grid onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}  container sm={12} md={12} xl={12} className='h-[23vh]'>
            <Grid item sm={4} md={4} xl={4}>
                <div className='flex text-2xl bold py-1' >{index+1}
                <div className={ !isHovering  ? 'scale-125 opacity-0 bgImg' : `bgImg ${data?.imgClass} object-fill min-h-[30vh] w-[70%] mx-auto transition scale-125 duration-500  ease-in-out`} ></div>
                </div>
            </Grid>
            <Grid sx={{borderLeft:'1px solid black',py:2,height:'23vh'}} item sm={8} md={8} xl={8}>
                <Grid container sm={12} md={12} xl={12} >
                    <Grid sm={6} md={6} xl={6} item>
                        <div className='px-4 bold text-4xl headers '>{data?.title?.toUpperCase()}</div>
                    </Grid>
                    <Grid sm={6} md={6} xl={6} item>
                        <div className='px-4 text-xl content '>{data?.desc}</div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>
  )
}

export default Service